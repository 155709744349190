let CSRF_TOKEN = '';
let select2DefaultOptions = {
	minimumResultsForSearch: -1,
	width: '100%'
};

/**
 * The main class of the JavaScript.
 *
 * @constructor
 */
function Main() {

	/**
	 * @type {Main} The instance of this class.
	 */
	let self = this;


	/**
	 * The Main constrcutor.
	 */
	this.init = function() {

		$('input[type="checkbox"]').dcCheckbox();
		$('input[type="radio"]').dcRadioButton();

		$('select').not('.select2-hidden-accessible').select2(select2DefaultOptions);

		this.registerOnClick('[data-delete-youtube-playlist]', self.deleteYoutubePlaylist);
		this.registerOnClick('[data-part-status]', self.toggleYouTubePartStatus);
		this.registerOnClick('[data-youtube-playlist-favorite]', self.toggleYouTubeFavoriteStatus);
		this.registerOnClick('[data-podcast-favorite]', self.togglePodcastFavoriteStatus);
		this.registerOnClick('[data-delete-podcast]', self.deletePodcast);
		this.registerOnClick('[data-episode-status]', self.togglePodcastEpisodeStatus);
		this.registerOnClick('[data-tv-show-favorite]', self.toggleTVShowFavoriteStatus);
		this.registerOnClick('[data-delete-tv-show]', self.deleteTVShow);
		this.registerOnClick('[data-tv-show-episode-status]', self.toggleTVShowEpisodeStatus);
		this.registerOnClick('[data-delete-movie]', self.deleteMovie);
		this.registerOnClick('[data-movie-status]', self.toggleMovieStatus);
		this.registerOnClick('[data-delete-music]', self.deleteMusic);
		this.registerOnClick('[data-delete-audiobook]', self.deleteAudioBook);
		this.registerOnClick('[data-delete-game]', self.deleteGame);
		this.registerOnClick('[data-delete-paper]', self.deletePaper);

		this.filterBox();

		initFunction('#tvShowName', self.initTVShowAutocomplete);
		initFunction('#movieName', self.initMovieAutocomplete);
	};

	/**
	 * Register for an click event with a event handler.
	 *
	 * @param {string} selector The selector.
	 * @param {object} callback The callback.
	 */
	this.registerOnClick = function(selector, callback) {
		$(document).on('click', selector, callback);
	};

	/**
	 * Toggles the filter box arrow.
	 */
	this.filterBox = function() {

		let filterBoxInner = $('#filterBoxInner');
		let filterBoxToggle = $('.filter-box-toggle');

		filterBoxInner.on('shown.bs.collapse', function() {
			let selects = $(this).find('select');
			selects.select2('destroy');
			selects.not('.select2-hidden-accessible').select2(select2DefaultOptions);
		});

		filterBoxInner.on('show.bs.collapse', function() {
			filterBoxToggle.find('i.fa').removeClass('fa-caret-down').addClass('fa-caret-up');
		});
		filterBoxInner.on('hide.bs.collapse', function() {
			filterBoxToggle.find('i.fa').removeClass('fa-caret-up').addClass('fa-caret-down');
		});
	};

	/**
	 * Deletes the given YouTube playlist.
	 */
	this.deleteYoutubePlaylist = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-youtube-playlist'));

		if(confirm(tex('youtube.delete_playlist')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-youtube-playlist',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-youtube-playlist="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};

	/**
	 * Toggles the YouTube part status.
	 */
	this.toggleYouTubePartStatus = function() {

		let obj = $(this);
		let id = parseInt(obj.data('playlist-id'));
		let youTubeID = obj.data('youtube-id');

		obj.find('[data-status]').html('<div data-status><i class="fa fa-spin fa-spinner"></i> ' + tex('global.loading') + '</div>');

		$.ajax({
			method: 'POST',
			url: '/ajax/toggle-youtube-part',
			data: { id: id, youtube_id: youTubeID, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'new_status') === true && _.has(obj, 'youtube_id') === true && _.has(obj, 'new_status_html') === true) {

					let resultObj = $('[data-youtube-id="' + obj.youtube_id + '"]');

					if(obj.new_status === 1) {
						resultObj.removeClass('status-not-watched');
					}
					else {
						resultObj.addClass('status-not-watched');
					}

					resultObj.find('[data-status]').html(obj.new_status_html);
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Toggles the YouTube playlist favorite status.
	 */
	this.toggleYouTubeFavoriteStatus = function () {

		let obj = $(this);
		let id = parseInt(obj.data('youtube-playlist-favorite'));

		$.ajax({
			method: 'POST',
			url: '/ajax/toggle-youtube-favorite-status',
			data: { id: id, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'id') === true && _.has(obj, 'new_status_html') === true) {
					let resultObj = $('[data-youtube-playlist-favorite="' + obj.id + '"]');
					resultObj.html(obj.new_status_html);
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Toggles the podcast favorite status.
	 */
	this.togglePodcastFavoriteStatus = function () {

		let obj = $(this);
		let id = parseInt(obj.data('podcast-favorite'));

		$.ajax({
			method: 'POST',
			url: '/ajax/toggle-podcast-favorite-status',
			data: { id: id, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'id') === true && _.has(obj, 'new_status_html') === true) {
					let resultObj = $('[data-podcast-favorite="' + obj.id + '"]');
					resultObj.html(obj.new_status_html);
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Deletes the given Podcast.
	 */
	this.deletePodcast = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-podcast'));

		if(confirm(tex('podcast.delete')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-podcast',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-podcast="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};

	/**
	 * Toggle the podcast episode status.
	 */
	this.togglePodcastEpisodeStatus = function() {

		let obj = $(this);
		let episodeID = parseInt(obj.data('episode-id'));
		let podcastID = obj.data('podcast-id');

		obj.find('[data-status]').html('<i class="fa fa-spin fa-spinner"></i>');

		$.ajax({
			method: 'POST',
			url: '/ajax/toggle-podcast-episode',
			data: { episode_id: episodeID, podcast_id: podcastID, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'new_status') === true && _.has(obj, 'episode_id') === true && _.has(obj, 'new_status_html') === true) {

					let resultObj = $('[data-episode-id="' + obj.episode_id + '"]');

					if(obj.new_status === 1) {
						resultObj.removeClass('status-not-watched');
					}
					else {
						resultObj.addClass('status-not-watched');
					}

					resultObj.find('[data-status]').html(obj.new_status_html);
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Loads the TV show autocomplete js.
	 */
	this.initTVShowAutocomplete = function() {
		new InputAutocomplete('#tvShowName', '/ajax/tv-shows/search', function(tmdbID) {
			$('#tvShowTMDBID').val(tmdbID);
		});
	};

	/**
	 * Loads the movie autocomplete js.
	 */
	this.initMovieAutocomplete = function() {
		new InputAutocomplete('#movieName', '/ajax/movies/search', function(tmdbID) {
			$('#movieTMDBID').val(tmdbID);
		});
	};

	/**
	 * Toggle the TV show status.
	 */
	this.toggleTVShowFavoriteStatus = function () {

		let obj = $(this);
		let id = parseInt(obj.data('tv-show-favorite'));

		$.ajax({
			method: 'POST',
			url: '/ajax/toggle-tv-show-favorite-status',
			data: { id: id, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'id') === true && _.has(obj, 'new_status_html') === true) {
					let resultObj = $('[data-tv-show-favorite="' + obj.id + '"]');
					resultObj.html(obj.new_status_html);
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Deletes the given row.
	 */
	this.deleteTVShow = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-tv-show'));

		if(confirm(tex('tv_show.delete')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-tv-show',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-tv-show="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};

	/**
	 * Toggle the TV show episode status.
	 */
	this.toggleTVShowEpisodeStatus = function() {

		let obj = $(this);
		let episodeID = parseInt(obj.data('episode-id'));
		let season = parseInt(obj.data('season'));
		let tvShowID = obj.data('tv-show-id');

		obj.find('[data-status]').removeClass('color-green').removeClass('color-orange').html('<i class="fa fa-spin fa-spinner"></i> ' + tex('global.loading'));

		$.ajax({
			method: 'POST',
			url: '/ajax/toggle-tv-show-episode',
			data: { episode_id: episodeID, tv_show_id: tvShowID, season: season, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'new_status') === true && _.has(obj, 'episode_id') === true && _.has(obj, 'new_status_html') === true) {

					let resultObj = $('[data-episode-id="' + obj.episode_id + '"]');
					let statusObj = resultObj.find('[data-status]');
					let progressBar = $('[data-season-bar="' + obj.season + '"]');
					let progressText = $('[data-season-count-text="' + obj.season + '"]');
					let current = progressBar.data('season-current');
					let total = progressBar.data('season-total');

					if(obj.new_status === 1) {
						current++;
						statusObj.addClass('color-green');
					}
					else {
						current--;
						statusObj.addClass('color-orange');
					}

					let percent = ((current / total) * 100);

					progressBar.data('season-current', current);
					progressBar.css('width', percent + '%');

					if(percent >= 100) {
						progressBar.removeClass('bg-danger').addClass('bg-success');
					}
					else {
						progressBar.removeClass('bg-success').addClass('bg-danger');
					}

					let progressTextStr = tex('tv_shows.season.progress_text');
					progressText.html(progressTextStr.replace('%current%', current).replace('%total%', total));

					statusObj.html(obj.new_status_html);
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Deletes the given row.
	 */
	this.deleteMovie = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-movie'));

		if(confirm(tex('movie.delete')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-movie',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-movie="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};

	/**
	 * Toggle the movie status.
	 */
	this.toggleMovieStatus = function() {

		let obj = $(this);
		let movieID = parseInt(obj.data('movie-status'));

		obj.html('<i class="fa fa-spin fa-spinner"></i>');

		$.ajax({
			method: 'POST',
			url: '/ajax/toggle-movie-status',
			data: { movie_id: movieID, csrf_token: CSRF_TOKEN }
		})
		.done(function(obj) {

			if(_.isObject(obj) === true) {
				if(_.has(obj, 'status') === true && _.has(obj, 'new_status') === true && _.has(obj, 'movie_id') === true && _.has(obj, 'new_status_html') === true) {

					let resultObj = $('[data-movie-status="' + obj.movie_id + '"]');
					let statusTextObj = $('[data-movie-status-text="' + obj.movie_id + '"]');

					resultObj.html('<i class="fa fa-arrow-alt-circle-down"></i>');
					statusTextObj.html(obj.new_status_html);
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			}
			else {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			}
		})
		.fail(function() {
			alert(tex('global.error') + ': ' + tex('error.internal_error'));
		});
	};

	/**
	 * Deletes the music track row.
	 */
	this.deleteMusic = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-music'));

		if(confirm(tex('music.delete')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-music',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-music="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};

	/**
	 * Deletes the audiobook row.
	 */
	this.deleteAudioBook = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-audiobook'));

		if(confirm(tex('audiobook.delete')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-audiobook',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-audiobook="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};

	/**
	 * Deletes the game row.
	 */
	this.deleteGame = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-game'));

		if(confirm(tex('game.delete')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-game',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-game="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};

	/**
	 * Deletes the paper row.
	 */
	this.deletePaper = function() {

		let obj = $(this);
		let id = parseInt(obj.data('delete-paper'));

		if(confirm(tex('paper.delete')) === true) {

			$.ajax({
				method: 'POST',
				url: '/ajax/delete-paper',
				data: { id: id, csrf_token: CSRF_TOKEN }
			})
			.done(function(obj) {

				if(_.isObject(obj) === true) {
					if(_.has(obj, 'status') === true) {
						if(_.has(obj, 'id') === true) {
							$('[data-paper="' + obj.id + '"]').remove();
						}
						else {
							alert(tex('global.error') + ': ' + tex('error.internal_error'));
						}
					}
					else {
						alert(tex('global.error') + ': ' + tex('error.internal_error'));
					}
				}
				else {
					alert(tex('global.error') + ': ' + tex('error.internal_error'));
				}
			})
			.fail(function() {
				alert(tex('global.error') + ': ' + tex('error.internal_error'));
			});
		}
	};


	// Call the class constructor
	this.init();
}


$(document).ready(function() {
	CSRF_TOKEN = $('input[name="csrf_token"]').val();
	new Main();
});